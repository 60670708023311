<template>
  <div id="app">
    <!-- <Navigation :project_name="project_name" v-if="this.$store.state.user" /> -->
    <Navigation :project_name="project_name" />
    <!-- <router-view class="container-fluid" :project_name="project_name" /> -->
    <main role="main" class="flex-shrink-0">
      <router-view :project_name="project_name" />
    </main>
    <Footer />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      project_name: 'ΤρελοςΦοιτητης',
    };
  },
  created() {
    let app_name = process.env.VUE_APP_NAME;
    let app_version = process.env.VUE_APP_VERSION;
    let app_git_commit = process.env.VUE_APP_GIT_COMMIT;
    console.log(app_name + '@' + app_version + '@' + app_git_commit);
  },
  methods: {},
};
</script>

<style>
/* #app {
    padding-left: 200px;
}
#app.collapsed {
    padding-left: 50px;
} */

/* body {
  padding-top: 60px;
} */

/* this is for fixed- navbar */
/* body {
  min-height: 75rem;
  padding-top: 4.5rem;
} */

html {
  position: relative;
  min-height: 100%;
}
/* this is for fixed- navbar  and fixed footer */
main > .container {
  padding: 60px 0 80px 0;
}
</style>
