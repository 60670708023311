<template>
  <div class="card h-100">
    <div class="card-body">
      <h2 class="card-title">Τελευταία Nέα!</h2>
      <div class="card-text" style="padding: 10px">
        <span data-testid="news-content">
          <span v-for="(item, idx) in news_items" :key="idx">
            <!-- {{ item }} -->
            <p>
              <em>{{ item.univ_name_short }} - {{ item.dep_name }}</em>
              -
              <strong>{{ item.course_name }}</strong>
              -
              <!-- <a :href="generate_item_permalink(item)"><span v-html="generate_greek_description(item)"></span></a> -->
              <router-link :to="generate_item_permalink(item)">
                <span v-html="generate_greek_description(item)"></span>
              </router-link>
              στις
              <!-- {{ format_date(item.date) }} -->
              <router-link :to="generate_item_permalink(item)">{{ format_date(item.date) }}</router-link>
            </p>
          </span>
        </span>
        <div v-if="loading_in_progress" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span v-html="loading_status"></span>
      </div>
    </div>
    <div class="card-footer">
      <router-link class="btn btn-primary btn-sm" to="/intro">
        Αναζήτησε, βρες αυτό που ψάχνεις, και κατέβασέ το στη στιγμή!
      </router-link>
    </div>
  </div>
</template>

<script>
  import { appAlertBuilder } from '@/helpers/app.js';

  const MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;
  const ERROR_MESSAGE = 'Παρουσιάστηκε σφάλμα στην ανάκτηση του περιεχομένου.';

  export default {
    data() {
      return {
        news_items: [],
        loading_in_progress: false,
        loading_status: null,
      };
    },
    props: {
      entityId: String,
      howManyNewsItems: Number,
      utmSource: String,
      utmMedium: String,
      utmCampaign: String,
    },
    methods: {
      format_date: function (date_string) {
        var self = this;
        try {
          return date_string.split('_').join('/');
        } catch (error) {
          console.error(date_string, error);
          return date_string;
        }
      },

      generate_item_permalink: function (item) {
        var self = this;
        if (![1, 2, 3, 4, 5, 6, 7].includes(parseInt(item.filetypeid))) {
          console.log('generate_item_permalink is not valid', item.filetypeid, item);
          return null;
        }

        let permalink_path = '';
        if (parseInt(item.filetypeid) == 1) {
          permalink_path = 'pastpapers';
        } else if (parseInt(item.filetypeid) == 2) {
          permalink_path = 'notes';
        } else if (parseInt(item.filetypeid) == 3) {
          permalink_path = 'solutions';
        } else if (parseInt(item.filetypeid) == 4) {
          permalink_path = 'examresults';
        } else if (parseInt(item.filetypeid) == 5) {
          permalink_path = 'pastpapers';
        } else if (parseInt(item.filetypeid) == 6) {
          permalink_path = 'guides';
        } else if (parseInt(item.filetypeid) == 7) {
          permalink_path = 'guides';
        }

        let permalink = `/${permalink_path}?utm_source=${self.utmSource}&utm_medium=${self.utmMedium}&utm_campaign=${
          self.utmCampaign
        }&viewid=${self.generate_viewid(item)}`;

        // console.log('permalink', permalink, item);
        return permalink;
      },

      generate_viewid: function (item) {
        if (![1, 2, 3, 4, 5, 6, 7].includes(parseInt(item.filetypeid))) {
          console.log('generate_viewid is not valid', item.filetypeid, item);
          return null;
        }

        let viewid = '';
        if ([1, 2, 3, 4].includes(parseInt(item.filetypeid))) {
          viewid = `${item.univid}-${item.depid}-${item.courseid}-${item.ufileid}`;
        }
        if (parseInt(item.filetypeid) == 5) {
          viewid = `${item.univid}-${item.depid}-${item.courseid}`;
        }
        if (parseInt(item.filetypeid) == 6) {
          viewid = `${item.univid}-${item.depid}-o-${item.ufileid}`;
        }
        if (parseInt(item.filetypeid) == 7) {
          viewid = `${item.univid}-${item.depid}-q-${item.ufileid}`;
        }

        // console.log('viewid', viewid, item);
        return viewid;
      },

      generate_greek_description: function (item) {
        if (![1, 2, 3, 4, 5, 6, 7].includes(parseInt(item.filetypeid))) {
          console.log('Generate_greek_description is not valid', item.filetypeid, item);
          return null;
        }

        let greek_descr = '';
        if (parseInt(item.filetypeid) == 1) {
          greek_descr = '<span class="badge rounded-pill bg-success">ΘΕΜΑΤΑ</span>';
        } else if (parseInt(item.filetypeid) == 2) {
          greek_descr = '<span class="badge rounded-pill bg-info">ΣΗΜΕΙΩΣΕΙΣ</span>';
        } else if (parseInt(item.filetypeid) == 3) {
          greek_descr = '<span class="badge rounded-pill bg-warning">ΛΥΣΕΙΣ</span>';
        } else if (parseInt(item.filetypeid) == 4) {
          greek_descr = '<span class="badge rounded-pill bg-danger">ΑΠΟΤΕΛΕΣΜΑΤΑ</span>';
        } else if (parseInt(item.filetypeid) == 5) {
          greek_descr = '<span class="badge rounded-pill bg-primary">ΥΛΗ</span>';
        } else if (parseInt(item.filetypeid) == 6) {
          greek_descr = '<span class="badge rounded-pill bg-dark">ΟΔΗΓΟΣ ΣΠΟΥΔΩΝ</span>';
        } else if (parseInt(item.filetypeid) == 7) {
          greek_descr = '<span class="badge rounded-pill bg-primary">ΠΡΟΓΡΑΜΜΑ ΕΞΕΤΑΣΤΙΚΗΣ</span>';
        }

        // console.log('greek descr', greek_descr, item);
        return greek_descr;
      },

      fetch_news: async function () {
        var self = this;
        self.loading_in_progress = true;
        self.loading_status = null;

        fetch(`${MAIN_ENDPOINT}/news/${self.entityId}`)
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            // console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.loading_in_progress = false;
              self.loading_status = '';

              // Populate news items
              self.news_items = data;
              // self.newsHTML = self.newsHTML_build(data, self.howManyNewsItems);
            }
          })
          .catch(function (error) {
            console.log(error);
            self.loading_in_progress = false;
            self.loading_status = appAlertBuilder(ERROR_MESSAGE + ' - ' + error, 'danger');
          });
      },
    },
    created() {
      var self = this;
      self.fetch_news();
    },
  };
</script>
