import { createStore } from 'vuex';
import { auth } from '@/services/firebase';
import { getCookies, setCookie } from '@/helpers/app.js';

const MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;

export default createStore({
  state: {
    user: null,
    token: null,
    email_verified: null,
    user_roles: [],
  },
  mutations: {
    // setUser sets the user and email_verified properties if applicable
    setUser(state, payload) {
      state.user = payload;
      if (payload != null) {
        state.email_verified = state.user.emailVerified;
      }
    },
    setEmailVerified(state, payload) {
      state.email_verified = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setUserRoles(state, payload) {
      state.user_roles = payload;
    },
    cleanup(state) {
      state.user = null;
      state.token = null;
      state.email_verified = null;
      state.user_roles = [];
    },

    set_tf_cookies(state, data) {
      let expires = new Date();
      expires.setTime(expires.getTime() + 31536000000); //1 year

      setCookie('tf_ads', data['ads'], expires.toUTCString());
      setCookie('tf_uxdy', data['uxdy'], expires.toUTCString());
      setCookie('tf_uxdy_text', data['uxdy_text'], expires.toUTCString());

      // self.updateTFUID(data['username']);
      let cur_username = data['username'];
      let all_uids_str = getCookies()['tf_uid'];
      if (all_uids_str != undefined) {
        let all_uids = all_uids_str.split('###');
        if (all_uids.indexOf(cur_username) > -1) {
          console.log('uid already present..');
        } else {
          console.log('uid was not present in cookies', getCookies());
          setCookie('tf_uid', getCookies()['tf_uid'] + '###' + cur_username, 2025);
          console.log('uid is present now in cookies', getCookies());
        }
      } else {
        console.log('tf_uid is not in coookies now', getCookies());
        setCookie('tf_uid', cur_username, 2025);
        console.log('tf_uid was not in coookies, was added now', getCookies());
      }
    },
  },
  actions: {
    async getValidToken({ commit }) {
      const user = auth.currentUser;
      // console.log('Token BEFORE:', state.token);

      if (user) {
        await user
          .getIdToken()
          .then((idToken) => {
            commit('setToken', idToken);
            // console.log('Token AFTER:', idToken);
          })
          .catch((error) => {
            console.error('Error refreshing token', error);
          });
      }
    },
    async getCookiesValues(context) {
      // console.log('async getCookiesValues to start');

      let tfuid_cookie = getCookies()['tf_uid'];
      let tfuid_value = tfuid_cookie == undefined ? '' : encodeURIComponent(tfuid_cookie);

      return fetch(`${MAIN_ENDPOINT}/users/signin`, {
        method: 'POST',
        headers: {
          'Authorization': context.state.token,
        },
        body: JSON.stringify({
          tfuid: tfuid_value,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          // console.log(response);
          let success = response.response.success;
          let message = response.response.message;
          let data = response.response.data;

          if (!success) {
            throw new Error(message);
          } else {
            // SET COOKIES
            context.commit('set_tf_cookies', data);
            return true;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
    },
  },
  modules: {},
});
