import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';

const DEBUG = process.env.VUE_APP_DEBUG_MODE == 'true';
const DEFAULT_TITLE = 'ΤΟ ΣΤΕΚΙ ΟΛΩΝ ΤΩΝ ΦΟΙΤΗΤΩΝ';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'ΤΟ ΣΤΕΚΙ ΟΛΩΝ ΤΩΝ ΦΟΙΤΗΤΩΝ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER HOME:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/intro',
    name: 'Intro',
    component: () => import(/* webpackChunkName: "intro" */ '@/views/Intro.vue'),
    meta: {
      title: 'ΑΡΧΕΙΑ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER INTRO:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/pastpapers',
    name: 'Pastpapers',
    component: () => import(/* webpackChunkName: "pastpapers" */ '@/views/Pastpapers.vue'),
    meta: {
      title: 'ΘΕΜΑΤΑ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER PASTPAPERS:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/notes',
    name: 'Notes',
    component: () => import(/* webpackChunkName: "notes" */ '@/views/Notes.vue'),
    meta: {
      title: ' ΣΗΜΕΙΩΣΕΙΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Notes:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import(/* webpackChunkName: "solutions" */ '@/views/Solutions.vue'),
    meta: {
      title: 'ΑΠΑΝΤΗΣΕΙΣ/ΛΥΣΕΙΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Solutions:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/guides',
    name: 'Guides',
    component: () => import(/* webpackChunkName: "guides" */ '@/views/Guides.vue'),
    meta: {
      title: 'ΣΠΟΥΔΕΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Guides:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "download" */ '@/views/Download.vue'),
    meta: {
      title: 'ΚΑΤΕΒΑΣΜΑ ΑΡΧΕΙΩΝ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Download:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      title: 'ΑΝΑΖΗΤΗΣΗ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Search:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/Checkout.vue'),
    meta: {
      title: 'ΑΓΟΡΑ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Checkout:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
        next();
      }
    },
  },

  {
    path: '/points',
    name: 'Points',
    component: () => import(/* webpackChunkName: "points" */ '@/views/Points.vue'),
    meta: {
      title: 'ΠΟΝΤΟΙ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Points:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/points-success',
    name: 'PointsSuccess',
    component: () => import(/* webpackChunkName: "points_success" */ '@/views/PointsSuccess.vue'),
    meta: {
      title: 'ΕΠΙΤΥΧΗΣ ΑΓΟΡΑ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER PointsSuccess:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/points-failure',
    name: 'PointsFailure',
    component: () => import(/* webpackChunkName: "points_failure" */ '@/views/PointsFailure.vue'),
    meta: {
      title: 'ΑΓΟΡΑ ΑΠΕΤΥΧΕ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER PointsFailure:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
    meta: {
      title: 'ΕΠΙΚΟΙΝΩΝΙΑ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Contact:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/FAQ.vue'),
    meta: {
      title: 'ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER FAQ:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '@/views/Unsubscribe.vue'),
    meta: {
      title: 'ΑΠΕΓΓΡΑΦΗ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Unsubscribe:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "upload" */ '@/views/Upload.vue'),
    meta: {
      title: 'ΑΝΕΒΑΣΜΑ ΑΡΧΕΙΩΝ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER Upload:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/rate-my-prof',
    name: 'RateMyProf',
    component: () => import(/* webpackChunkName: "ratemyprof" */ '@/views/RateMyProf.vue'),
    meta: {
      title: 'RateMyProf',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER RateMyProf:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/rate-my-prof/:profid',
    name: 'RateMyProfViewProf',
    component: () => import(/* webpackChunkName: "ratemyprof_view_prof" */ '@/views/RateMyProfViewProf.vue'),
    meta: {
      title: 'ΠΡΟΒΟΛΗ ΚΑΘΗΓΗΤΗ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER RateMyProfViewProf:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/rate-my-prof/add-prof',
    name: 'RateMyProfAddProf',
    component: () => import(/* webpackChunkName: "ratemyprof_add_prof" */ '@/views/RateMyProfAddProf.vue'),
    meta: {
      title: 'ΠΡΟΣΘΗΚΗ ΚΑΘΗΓΗΤΗ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER RateMyProfAddProf:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/rate-my-prof/:profid/add-review',
    name: 'RateMyProfAddReview',
    component: () => import(/* webpackChunkName: "ratemyprof_add_review" */ '@/views/RateMyProfAddReview.vue'),
    meta: {
      title: 'ΠΡΟΣΘΗΚΗ ΚΡΙΤΙΚΗΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER RateMyProfAddReview:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/signup',
    name: 'UserSignUp',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/UserSignUp.vue'),
    meta: {
      title: 'ΕΓΓΡΑΦΗ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER SignUP:', store.state.user);
      }
      if (store.state.user != null) {
        next('/account');
      } else {
        document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
        next();
      }
    },
  },

  {
    path: '/signin',
    name: 'UserSignIn',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/UserSignIn.vue'),
    meta: {
      title: 'ΕΙΣΟΔΟΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER SignIN:', store.state.user);
      }
      if (store.state.user != null) {
        next('/account');
      } else {
        document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
        next();
      }
    },
  },

  {
    path: '/manage-account',
    name: 'UserManageAccount',
    component: () => import(/* webpackChunkName: "manage_account" */ '@/views/UserManageAccount.vue'),
    meta: {
      title: 'ΔΙΑΧΕΙΡΙΣΗ ΛΟΓΑΡΙΑΣΜΟΥ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER ManageAccount:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/verify-email',
    name: 'UserVerifyEmail',
    component: () => import(/* webpackChunkName: "verify_email" */ '@/views/UserVerifyEmail.vue'),
    meta: {
      title: 'ΕΠΙΒΕΒΑΙΩΣΗ EMAIL',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER VerifyEmail:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/reset-pwd',
    name: 'UserResetPassword',
    component: () => import(/* webpackChunkName: "reset_pwd" */ '@/views/UserResetPassword.vue'),
    meta: {
      title: 'ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER ResetPassword:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/change-pwd',
    name: 'UserChangePassword',
    component: () => import(/* webpackChunkName: "change_pwd" */ '@/views/UserChangePassword.vue'),
    meta: {
      title: 'ΕΙΣΑΓΩΓΗ ΝΕΟΥ ΚΩΔΙΚΟΥ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER ChangePassword:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/delete-account',
    name: 'UserDeleteAccount',
    component: () => import(/* webpackChunkName: "delete_account" */ '@/views/UserDeleteAccount.vue'),
    meta: {
      title: 'ΔΙΑΓΡΑΦΗ ΛΟΓΑΡΙΑΣΜΟΥ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER DeleteAccount:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
        next();
      }
    },
  },

  {
    path: '/verify-student',
    name: 'UserVerifyStudent',
    component: () => import(/* webpackChunkName: "verify_student" */ '@/views/UserVerifyStudent.vue'),
    meta: {
      title: 'ΕΠΙΒΕΒΑΙΩΣΗ ΦΟΙΤΗΤΙΚΗΣ ΙΔΙΟΤΗΤΑΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER UserVerifyStudent:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
    meta: {
      title: 'ΛΟΓΑΡΙΑΣΜΟΣ',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER ACCOUNT:', store.state.user);
      }
      if (store.state.user == null) {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      } else {
        document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
        next();
      }
    },
  },

  {
    path: '/paypal-self-service',
    name: 'PaypalSelfService',
    component: () => import(/* webpackChunkName: "paypal_self_service" */ '@/views/PaypalSelfService.vue'),
    meta: {
      title: 'Paypal - Self Service',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER PAYPAL_SELF_SERVICE:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "page_not_found" */ '@/views/PageNotFound.vue'),
    meta: {
      title: '404',
    },
    beforeEnter: (to, from, next) => {
      if (DEBUG) {
        console.log('BEFORE ENTER PageNotFound:', store.state.user);
      }
      document.title = `ΤρελόςΦοιτητής - ${to.meta.title}` || `ΤρελόςΦοιτητής - ${DEFAULT_TITLE}`;
      next();
    },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  // },
];

// const routes = [
//   {
//     path: '/:pathMatch(.*)*',
//     name: 'Maintenance',
//     component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance.vue'),
//     beforeEnter: (to, from, next) => {
//       console.log('BEFORE ENTER Maintenance:', store.state.user);
//       next();
//     },
//   },
// ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

// import { gaLogger } from '@/services/firebase';
// router.afterEach((to, from) => {
//   console.log('from', from, 'to', to);
//   // gaLogger('page_view', { to: `${to.fullPath}`, from: `${from.fullPath}` });
//   window.dataLayer = window.dataLayer || [];
//   window.dataLayer.push({
//     event: 'page_view',
//   });
// });

export default router;
