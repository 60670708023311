function getCookies(showpopup) {
  var c = document.cookie.split(';');
  var out = {};
  for (var i = 0; i < c.length; i++) {
    var cparts = c[i].split('=');
    out[cparts[0].trim()] = decodeURIComponent(cparts[1]);
  }
  if (showpopup) {
    alert(out);
  }
  return out;
}

function setCookie(cookieName, cookieValue, cookieExpiry) {
  if (window.location.href.indexOf('localhost') > -1) {
    console.log('Setting cookie with no domain arg, we are on localhost..');
    document.cookie = cookieName + '=' + cookieValue + '; expires=' + cookieExpiry + '; path=/';
  } else {
    document.cookie =
      cookieName + '=' + cookieValue + '; expires=' + cookieExpiry + '; path=/; domain=trelosfoititis.gr';
  }
}

function deleteCookie(cookieName) {
  if (window.location.href.indexOf('localhost') > -1) {
    console.log('Deleting cookie with no domain arg, we are on localhost..');
    document.cookie = cookieName + '=; expires=2010; path=/; Max-Age=-99999999;';
  } else {
    document.cookie = cookieName + '=; expires=2010; path=/; domain=trelosfoititis.gr; Max-Age=-99999999;';
  }
}

function geturlparamvalue(name) {
  var local_name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regexS = '[\\?&]' + local_name + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results != null) {
    return [true, results[1]];
  } else {
    return [false, ''];
  }
}

function appAlertBuilder(alert_html, alert_type) {
  if (alert_type === undefined) {
    alert_type = 'info';
  }
  return '<div class="alert alert-' + alert_type + '" role="alert">' + alert_html + '</div>';
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

function formatTime(timestamp) {
  const d = new Date(timestamp);

  const dd = d.getDate();
  const dd_final = dd < 9 ? '0' + dd : dd;

  const mm = d.getMonth() + 1;
  const mm_final = mm < 9 ? '0' + mm : mm;

  const hh = d.getHours();
  const hh_final = hh < 9 ? '0' + hh : hh;

  const min = d.getMinutes();
  const min_final = min < 9 ? '0' + min : min;

  const ss = d.getSeconds();
  const ss_final = ss < 9 ? '0' + ss : ss;

  const time = `${dd_final}/${mm_final}/${d.getFullYear()} ${hh_final}:${min_final}:${ss_final}`;
  return time;
}

function get_uxdy_from_cookies() {
  let tmp_val = '';
  try {
    tmp_val = getCookies()['tf_uxdy'].split('_').join('-');
  } catch (error) {
    console.log(error);
    tmp_val = '';
  }
  return tmp_val;
}

export {
  getCookies,
  setCookie,
  deleteCookie,
  geturlparamvalue,
  appAlertBuilder,
  uuidv4,
  formatTime,
  get_uxdy_from_cookies,
};
